<script>
import onlyCyrillic from "@/directives/onlyCirillick.js";
import validateEmail from "@/directives/validateEmail.js";
import validatePhone from "@/directives/validatePhone.js";
import TabsBlock from "../ui/TabsBlock.vue";
import { MaskInput } from "vue-3-mask";
import axios from "axios";
import { myMixin } from "@/mixin";

export default {
  name: "RegisterBlock",
  directives: {
    onlyCyrillic,
    validateEmail,
    validatePhone,
  },
  components: {
    TabsBlock,
    MaskInput,
  },
  mixins: [myMixin],
  data() {
    return {
      tabsArray: [ "По почте", "Vk", "Yandex"],
      message: "",
      user: this.$store.getters.getUser,
      apiUrl: this.$store.getters.getApiUrl,
      apiDomain: this.$store.getters.getApiDomain,
      isEmailValid: false,
      isPhoneValid: false,
      error: "",
      showDone: false,
    };
  },
  methods: {
    updateEmailValidity(isValid) {
      this.isEmailValid = isValid;
    },
    updatePhoneValidity(isValid) {
      this.isPhoneValid = isValid; // Обновляем флаг в зависимости от валидности телефона
    },
    popupToogle() {
      const popup = document.querySelector(".popup");
      popup.classList.toggle("active");
    },
    cleanPhoneNumber(phoneNumber) {
      const cleanedNumber = phoneNumber.replace(/[^0-9]/g, "");
      return cleanedNumber;
    },
    switchTo(type) {
      this.$emit("switchTo", type);
    },
    changeTab(tab) {
      this.message = tab;
    },
    userRegisterEmail() {
      if (!this.isEmailValid) {
        this.error = "Некорректная почта";
        this.$emit("erroruser", this.error);
        return;
      }
      this.error = "";
      let authGet = `&auth=${this.user.username}:${this.user.auth_key}`;
      let params = {
        type: "email",
        username: this.inputEmail,
        password: this.inputPassword,
        i: this.inputI,
        email: this.inputEmail,
        tel: this.inputUsername,
      };
      console.log(params);
      axios
        .post(this.apiUrl + "api-user/register" + authGet, params, {
          headers: { "Content-Type": "multipart/form-data" },
        })
        .then((response) => {
          console.log("userRegister", response);
          if (response.data.status) {
            this.$store.commit("setUserData", response.data.user);
            this.showDone = true;
            this.$emit("registerSuccess");
          } else {
            this.$emit("erroruser", response.data.error);
            console.log(response.data.error);
          }
        })
        .catch((error) => {
          this.$emit("erroruser", error);
          console.log(error);
        });
    },
    userRegisterPhone() {
      if (!this.isPhoneValid) {
        this.error = "Некорректный номер";
        this.$emit("erroruser", this.error);
        return;
      }
      this.error = "";
      let authGet = `&auth=${this.user.username}:${this.user.auth_key}`;
      let params = {
        type: "phone",
        username: this.cleanPhoneNumber(this.inputUsername),
        password: this.inputPassword,
        i: this.inputI,
        email: this.cleanPhoneNumber(this.inputUsername) + "@media495.ru",
        tel: this.cleanPhoneNumber(this.inputUsername),
      };
      console.log(params);
      axios
        .post(this.apiUrl + "api-user/register" + authGet, params, {
          headers: { "Content-Type": "multipart/form-data" },
        })
        .then((response) => {
          console.log("userRegister", response);
          if (response.data.status) {
            this.$store.commit("setUserData", response.data.user);
            this.showDone = true;
            this.$emit("registerSuccess");
            this.$router.push(
              "/success?token=" +
                response.data.user.password_reset_token +
                "&type=phone"
            );
          } else {
            this.$emit("erroruser", response.data.error);
          }
        })
        .catch((error) => {
          this.$emit("erroruser", error);
        });
    },
  },
};
</script>

<template>
  <div class="form registrtion__form">
    <div class="head-h3">Регистрация</div>
    <TabsBlock
      @changeTab="changeTab"
      :tabsArray="tabsArray"
      :curTab="'По почте'"
    ></TabsBlock>
    <div v-if="message == 'По почте'" class="RegisterItem">
      <div class="form__content">
        <div class="form__item">
          <label class="form__label">Имя *</label>
          <input
            v-model="inputI"
            v-only-cyrillic
            type="text"
            class="form__input"
          />
        </div>

        <div class="form__item">
          <label class="form__label">Электронная почта *</label>
          <input
            type="text"
            v-validate-email="updateEmailValidity"
            v-model="inputEmail"
            class="form__input"
          />
        </div>
      </div>
      <div class="form__row">
        <div class="form__item">
          <label class="form__label passRegInput">Пароль *</label>
          <input
            type="password"
            v-model="inputPassword"
            class="form__input"
            readonly
            onfocus="this.removeAttribute('readonly')"
          />
        </div>
        <div class="form__item">
          <label class="form__label">Подтверждение пароля *</label>
          <input
            type="password"
            class="form__input"
            readonly
            onfocus="this.removeAttribute('readonly')"
          />
        </div>
      </div>
      <div class="form__footer">
        <button @click="userRegisterEmail" class="btn btn-blue">
          Регистрация
        </button>
      </div>
      <div class="form__footer">
        <a @click="switchTo('login')" class="link"
          >Уже есть аккаунт? <span>Войти</span></a
        >
      </div>
    </div>
    <div v-if="message == 'По номеру'" class="RegisterItem">
      <div class="form__content">
        <div class="form__item">
          <label class="form__label">Имя *</label>
          <input
            v-model="inputI"
            v-only-cyrillic
            type="text"
            class="form__input"
          />
        </div>
        <div class="form__item">
          <label class="form__label">Номер телефона *</label>
          <MaskInput
            v-model="inputUsername"
            class="form__input phone-input"
            mask="+7(###) ###-##-##"
            v-validate-phone="updatePhoneValidity"
          />
        </div>
      </div>
      <div class="form__row">
        <div class="form__item">
          <label class="form__label passRegInput">Пароль *</label>
          <input
            type="password"
            v-model="inputPassword"
            class="form__input"
            readonly
            onfocus="this.removeAttribute('readonly')"
          />
        </div>
        <div class="form__item">
          <label class="form__label">Подтверждение пароля *</label>
          <input
            type="password"
            class="form__input"
            readonly
            onfocus="this.removeAttribute('readonly')"
          />
        </div>
      </div>
      <div class="form__footer">
        <button
          @click="userRegisterPhone"
          class="btn btn-blue"
          :class="{ 'btn-disabled': showDone }"
        >
          Регистрация
        </button>
      </div>
      <div class="form__footer">
        <a @click="switchTo('login')" class="link"
          >Уже есть аккаунт? <span>Войти</span></a
        >
      </div>
    </div>
  </div>
</template>

<style scoped>
.close {
  cursor: pointer;
  position: absolute;
  top: 20px;
  right: 20px;
}
.login {
  display: flex;
  justify-content: center;
  height: 100vh;
  width: 100%;
  overflow: hidden;
}
.login__content {
  width: 100%;
  max-width: 536px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 40px;
  padding: 22px 0;
  height: 100%;
  margin: 0 auto;
}
.RegisterItem{
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.login__head {
  display: flex;
  flex-direction: column;
  gap: 36px;
}
.logo {
  max-width: 205px;
}
.back {
  color: var(--Black-1200, #2e2e2e);
  font-family: "Suisse Intl";
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  display: flex;
  align-items: center;
  gap: 8px;
  text-decoration: none;
}
.form {
  display: flex;
  flex-direction: column;
  gap: 36px;
  max-width: 536px;
}
.form__item {
  display: flex;
  flex-direction: column;
  gap: 6px;
  max-width: 536px;
}
.form__label {
  color: var(--Black-1300, #1d1d1b);
  font-family: Onest;
  font-size: 16px;
  font-weight: 400;
  line-height: 20.8px;
  text-align: left;
}
.form__input,
.form__input:autofill,
.form__input:-webkit-autofill,
.form__input:-internal-autofill-selected {
  border-radius: 2px;
  background: var(--Black-200, #f2f2f2);
  color: var(--Black, #111);
  font-family: Onest;
  font-size: 16px;
  font-weight: 400;
  line-height: 20.8px;
  text-align: left;

  padding: 14px 16px;
  outline: none;
  border: 1px solid #eaeaea;
  width: 100%;
  max-width: 100%;
}
.form__input:hover,
.form__input:focus {
  border-radius: 2px;
  background: var(--Black-300, #eaeaea);
  list-style: none;
  outline: none;
}
.form__content {
  display: flex;
  flex-direction: column;
  gap: 22px;
}
.form__row {
  display: flex;
  gap: 22px;
  justify-content: space-between;
}
.forgot {
  color: var(--Black-1200, #2e2e2e);
  font-family: Onest;
  font-size: 16px;
  font-weight: 400;
  line-height: 20.8px;
  text-align: left;

  text-decoration-line: underline;
  margin-top: 8px;
}
.btn-black {
  width: 100%;
}
.link {
  color: var(--Black-1200, #2e2e2e);
  font-family: Onest;
  font-size: 16px;
  font-weight: 400;
  line-height: 20.8px;
  text-align: left;
}
.link span {
  text-decoration-line: underline;
}
.login__image {
  width: 50%;
  flex-shrink: 0;
  height: 100%;
  background: #0850cd;
  position: relative;
  z-index: 2;
}
.login__image img {
  height: 100%;
  object-fit: cover;
  width: 100%;
}
.login__wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 50%;
  flex-shrink: 0;
  padding-right: 40px;
  padding-left: 40px;
  height: 100%;
}
.login-image {
  display: block;
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: center;
}
.politic__text {
  color: var(--Black-1200, #2e2e2e);
  font-family: Onest;
  font-size: 16px;
  font-weight: 400;
  line-height: 20.8px;
  text-align: left;
}
.politic__text span {
  text-decoration-line: underline;
}
.popup {
  background: rgba(31, 31, 31, 0.7);
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  padding: 0 20px;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s;
}
.popup.active {
  opacity: 1;
  visibility: visible;
  transition: all 0.3s;
}
.popup__content {
  border-radius: 2px;
  background: var(--White, #fff);
  padding: 40px 36px;
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 33px;
  max-width: 586px;
  width: 100%;
}
.popup__head {
  color: var(--Black-1300, #1d1d1b);
  text-align: center;
  font-family: Onest;
  font-size: 28px;
  font-weight: 400;
  line-height: 20.8px;
}

.politic {
  padding-bottom: 20px;
}

@media screen and (max-width: 960px) {
  .form__row {
    flex-direction: column;
  }
}

@media screen and (max-width: 767px) {
  .login__image {
    display: none;
  }
  .login__wrapper {
    width: 100%;
    padding-right: 24px;
    padding-left: 24px;
  }
  .login__content {
    max-width: 100%;
  }
  .form {
    width: 100%;
    margin: 0 auto;
  }
  .link {
    font-size: 15px;
  }
  .politic {
    max-width: 536px;
    margin: 0 auto;
  }
}
</style>