<script>
import HeaderBlock from "../blocks/HeaderBlock.vue";
import FooterBlock from "../blocks/FooterBlock.vue";
import Breadcrumbs from "../ui/Breadcrumbs.vue";
import SubscribeBlock from "../blocks/SubscribeBlock.vue";
import PdfViewer from "../elements/PdfViewer.vue";
import axios from "axios";
export default {
  name: "LibraryPage",
  components: {
    HeaderBlock,
    FooterBlock,
    Breadcrumbs,
    SubscribeBlock,
    PdfViewer,
  },
  data() {
    return {
      book: {},
      user: this.$store.getters.getUser,
      apiUrl: this.$store.getters.getApiUrl,
      apiDomain: this.$store.getters.getApiDomain,
      bookFlag: false,
    };
  },
  methods: {
    getBook() {
      let authGet = `&auth=${this.user.username}:${this.user.auth_key}`;
      axios
        .get(
          this.apiUrl +
            "api-book/get" +
            authGet +
            `&id=${this.$route.params.id}`
        )
        .then((response) => {
          this.book = response.data.book;
          console.log('book',this.book);
          this.bookFlag = true;
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  mounted() {
    this.getBook();
  },
};
</script>

<template>
  <HeaderBlock />
  <Breadcrumbs
    :page="[
      { name: 'Библиотека', link: '/library' },
      { name: book.title, link: '' },
    ]"
    :bannerHead="{ name: 'book', uniq: false }"
    :title="book.title"
  />
  <div class="library mBlock">
    <div class="container">
      <div class="library__content">
        <PdfViewer v-if="bookFlag" :pdfFile="apiDomain + 'web/uploads/' + book.link" />
      </div>
    </div>
  </div>
  <SubscribeBlock />
  <FooterBlock />
</template>
<style scoped>
.library__content{
  display: flex;
  justify-content: center;
}
</style>