<script>
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import axios from "axios";

import PopupVideo from "../elements/PopupVideo.vue";
export default {
  name: "HomeVideoBlock",
  components: {
    PopupVideo,
  },
  data() {
    return {
      videoPosts: [],
      isPlaying: false,
      timeline: null,
      user: this.$store.getters.getUser,
      apiUrl: this.$store.getters.getApiUrl,
      apiDomain: this.$store.getters.getApiDomain,
      iframe: null,
      popupShow: false,
      displayCount: 4,
    };
  },
  methods: {
    getContent() {
      let authGet = `&auth=${this.user.username}:${this.user.auth_key}`;

      axios
        .get(this.apiUrl + "api-video/get-list/" + authGet + "&limit=5")
        .then((response) => {
          this.videoPosts = response.data.videos;
          console.log(this.videoPosts);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    togglePlay(iframe) {
      this.popupShow = true;
      this.iframe = iframe;
    },
    closePopup() {
      this.popupShow = false;
      this.iframe = null;
    },
    truncate(text, maxLength) {
      let mobile = window.innerWidth < 1024;
      if (mobile) {
        maxLength = 28;
      }
      if (!text) return "";
      return text.length > maxLength
        ? text.substring(0, maxLength) + "..."
        : text;
    },
  },
  mounted() {
    this.getContent();
    gsap.registerPlugin(ScrollTrigger);
    this.timeline = gsap.timeline();
    let posts = document.querySelectorAll(".post__content");
    posts.forEach((post) => {
      this.timeline.fromTo(
        post,
        { y: "50%", opacity: 0.2 },
        {
          opacity: 1,
          y: 0,
          scrollTrigger: {
            trigger: post,
            start: "top bottom",
            end: "+=200",
            scrub: 2,
          },
        }
      );
    });
    const script = document.createElement("script");
    script.src = "https://vk.com/js/api/videoplayer.js";
    document.head.appendChild(script);
  },
};
</script>

<template>
  <div class="home__video mBlock">
    <div class="container">
      <div class="home__video__wrapper">
        <div class="head-h2">Видеоматериалы</div>
        <div class="posts">
          <div
            class="post"
            v-for="v in videoPosts.splice(0, this.displayCount)"
            :key="v.id"
          >
            <div class="video">
              <div
                :id="'btnVBlock' + v.id"
                class="btn__playVideo"
                @click="togglePlay(v.link)"
              >
                <svg
                  class="playSvg"
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M23 12.0002C23 10.9652 22.47 9.93018 21.409 9.35318L8.597 2.38518C6.534 1.26418 4 2.72418 4 5.03318V12.0002H23Z"
                    fill="white"
                  />
                  <path
                    d="M8.597 21.615L21.409 14.647C21.89 14.3924 22.2923 14.0113 22.5727 13.5448C22.8531 13.0784 23.0008 12.5442 23 12H4V18.967C4 21.277 6.534 22.736 8.597 21.615Z"
                    fill="white"
                  />
                </svg>
                <svg
                  class="pauseSvg"
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                >
                  <path
                    d="M1.66602 5.00033C1.66602 3.42866 1.66602 2.64366 2.15435 2.15533C2.64268 1.66699 3.42768 1.66699 4.99935 1.66699C6.57102 1.66699 7.35602 1.66699 7.84435 2.15533C8.33268 2.64366 8.33268 3.42866 8.33268 5.00033V15.0003C8.33268 16.572 8.33268 17.357 7.84435 17.8453C7.35602 18.3337 6.57102 18.3337 4.99935 18.3337C3.42768 18.3337 2.64268 18.3337 2.15435 17.8453C1.66602 17.357 1.66602 16.572 1.66602 15.0003V5.00033ZM11.666 5.00033C11.666 3.42866 11.666 2.64366 12.1543 2.15533C12.6427 1.66699 13.4277 1.66699 14.9993 1.66699C16.571 1.66699 17.356 1.66699 17.8443 2.15533C18.3327 2.64366 18.3327 3.42866 18.3327 5.00033V15.0003C18.3327 16.572 18.3327 17.357 17.8443 17.8453C17.356 18.3337 16.571 18.3337 14.9993 18.3337C13.4277 18.3337 12.6427 18.3337 12.1543 17.8453C11.666 17.357 11.666 16.572 11.666 15.0003V5.00033Z"
                    fill="white"
                  />
                </svg>
              </div>
              <img
                class="poster"
                :src="apiDomain + 'web/uploads/' + v.poster"
                alt=""
              />
            </div>
            <div class="post__content">
              <div class="kategory">
                <p class="description-category">
                  {{ v.category.name }}
                </p>
              </div>
              <h3 class="headPost">
                {{ truncate(v.title, 54) }}
              </h3>
              <p class="sub-headPost">
                {{ v.text }}
              </p>
            </div>
          </div>
        </div>
      </div>
      <router-link class="btn-opacity" :to="'/videos'"
        >Больше видео
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="42"
          height="42"
          viewBox="0 0 42 42"
          fill="none"
        >
          <path
            d="M5.25 21H36.75"
            stroke="#333333"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M31.5 26.25L36.75 21L31.5 15.75"
            stroke="#333333"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </router-link>
    </div>
  </div>
  <PopupVideo v-if="popupShow" :item="iframe" @closePopup="closePopup" />
</template>
<style scoped>
.description-category {
  color: var(--Neutral-100, #fff);
  font-family: Onest;
  font-size: 26px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
}
.home__video__wrapper {
  display: flex;
  flex-direction: column;
  gap: 30px;
}
.posts {
  display: flex;
  flex-wrap: wrap;
  gap: 22px;
}
.btn-opacity {
  margin-left: auto;
}
.post {
  position: relative;
  width: calc((100% / 2) - 22px);
}
.post .poster {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.post .video {
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
}
.video::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0.0001) 0%,
    rgba(0, 0, 0, 0.3) 100%
  );
  z-index: 1;
}
.post .post__content {
  position: absolute;
  left: 44px;
  bottom: 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  z-index: 2;
}
.post:nth-of-type(1),
.post:nth-of-type(2) {
  width: calc((100% / 2) - 22px);
}
.headPost {
  color: var(--Neutral-100, #fff);
  font-family: Onest;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px; /* 122.222% */
}
.sub-headPost {
  color: var(--Neutral-100, #fff);
  font-family: Onest;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  max-width: 302px;
}
.btn__playVideo {
  position: absolute;
  top: 43px;
  right: 43px;
  z-index: 2;
  cursor: pointer;
  border-radius: 90px;
  background: rgba(111, 111, 111, 0.3);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 13px;
  width: 50px;
  height: 50px;
}
.kategory span {
  color: var(--Neutral-100, #fff);
  font-family: Onest;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px; /* 183.333% */
  text-transform: uppercase;
}
.kategory {
  border-bottom: 1px solid #b9b9b9;
  width: 100%;
  max-width: 322px;
  padding-bottom: 10px;
}
.post:nth-of-type(3) .kategory span,
.post:nth-of-type(4) .kategory span {
  color: var(--383838, #a2a2a2);
}
@media screen and (max-width: 1024px) {
  .posts {
    display: flex;
    flex-direction: column;
  }
  .post .video {
    max-height: none !important;
  }
  .post {
    width: 100% !important;
  }
  .post .post__content {
    left: 20px;
  }
  .btn__playVideo {
    top: 20px;
    right: 20px;
    width: 30px;
    height: 30px;
    padding: 6px;
  }
}
</style>