<script>
import HeaderBlock from "../blocks/HeaderBlock.vue";
import FooterBlock from "../blocks/FooterBlock.vue";
import Breadcrumbs from "../ui/Breadcrumbs.vue";
import axios from "axios";
export default {
  name: "AfishaPage",
  components: {
    HeaderBlock,
    FooterBlock,
    Breadcrumbs,
  },
  data() {
    return {
      local: this.$store.getters.getLocal,
    }
  },
  methods: {
    // enterSort(event) {
    //   let mobile = window.innerWidth < 1024;
    //   if (mobile) { return ;}
    //   let parent = event.currentTarget;
    //   let sortText = parent.querySelector(".sort__text");
    //   let sortRating = parent.querySelector(".sort__rating");

    //   sortText.classList.add("active");
    //   sortRating.classList.add("active");
    // },
    // leaveSort(event) {
    //   let mobile = window.innerWidth < 1024;
    //   if (mobile) { return ;}
    //   let parent = event.currentTarget;
    //   let sortText = parent.querySelector(".sort__text");
    //   let sortRating = parent.querySelector(".sort__rating");

    //   sortText.classList.remove("active");
    //   sortRating.classList.remove("active");
    // },
    // handleSortClick(event) {
    //   let target = event.currentTarget;
    //   let sortTheme = target.getAttribute("data-sorttheme");
    //   this.theme = sortTheme || null;
    //   this.selectedCategory = null; // сбрасываем выбранную категорию при смене темы
    //   this.sortItemsAfisha();
    //   let filterText = target
    //     .closest(".products__sort-rating")
    //     .querySelector(".sort__text span");
    //   filterText.textContent = sortTheme || "Все видео";
    // },

    // sortItem(event) {
    //   let target = event.currentTarget;
    //   let category = target.getAttribute("data-sort");

    //   this.selectedCategory = category === "allVideos" ? null : category; // обновляем выбранную категорию
    //   this.sortItemsAfisha(); // пересортировать видео с учетом категории
    //   if (category === "allVideos") {
    //     category = 'Все видео';
    //   }
    //   const filterText = target
    //     .closest(".products__sort-rating")
    //     .querySelector(".sort__text span");
    //   filterText.textContent = this.truncate(category, 20) || "Все категории";
    // },
    // sortItemsAfisha(sort = false) {
    //   this.openAudios = [];
    //   if (this.theme) {
    //     const theme = this.audioThemes.find((t) => t.theme.name === this.theme);
    //     if (theme) {
    //       this.curentTheme = theme;
    //       console.log("curentTheme", this.curentTheme);
    //       this.currentCategories = [];
    //       for (let a of theme.audios) {
    //         let idCategory = this.currentCategories.find((c) => c.id === a.category.id);
    //         if (!idCategory) {
    //           this.currentCategories.push(a.category);
    //         }
    //       }
    //       console.log("currentCategories", this.currentCategories);

    //       this.openAudios = theme.audios
    //         .filter(
    //           (audio) =>
    //             !this.selectedCategory ||
    //             audio.category.name === this.selectedCategory
    //         )
    //         .map((audio) => ({
    //           ...audio,
    //           unikId: this.generateUnikId(),
    //         }));
    //     }
    //   } else {
    //     this.audioThemes.forEach((t) => {
    //       t.audios.forEach((audio) => {
    //         this.openAudios.push({ ...audio, unikId: this.generateUnikId() });
    //       });
    //     });
    //   }

    //   if (sort) {
    //     this.openAudios = this.openAudios.filter((audio) =>
    //       audio.kategory.includes(sort)
    //     );
    //   }

    //   this.openAudios.forEach((audio) => {
    //     audio.displayCount = this.getInitialDisplayCount;
    //   });
    //   console.log("openAudios", this.openAudios);

    // },
    getAfisha() {
      let params =
        "https://pro.culture.ru/api/2.5/locales?apiKey=vj4nqaldqshsgcawd8yw";
        console.log(params);
        
      axios
        .get(params)
        .then((response) => {
          console.log(response);
        });
    },
  },
  mounted() {
    this.getAfisha();
  },
};
</script>

<template>
  <HeaderBlock />
  <Breadcrumbs
    :page="[{ name: 'Видео', link: '/videos' }]"
    :title="'Видео'"
    :bannerHead="{ name: 'videos', uniq: false }"
  />
  <div class="afisha">
    <div class="container">
      <div class="afisha__content">
        <div class="link__filteres">
          <div
            class="products__sort-rating themesFilter"
            @mouseover="enterSort"
            @mouseleave="leaveSort"
            @click="toggleSort"
          >
            <div class="sort__text">
              <span>Мероприятия</span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                height="18"
                viewBox="0 0 18 18"
                fill="none"
              >
                <path
                  d="M9.64021 11.7682C9.26936 12.0773 8.73069 12.0773 8.35984 11.7682L2.35984 6.76822C1.93556 6.41466 1.87824 5.78409 2.2318 5.35982C2.58537 4.93554 3.21593 4.87821 3.64021 5.23178L9.00003 9.69829L14.3598 5.23178C14.7841 4.87821 15.4147 4.93554 15.7682 5.35982C16.1218 5.78409 16.0645 6.41466 15.6402 6.76822L9.64021 11.7682Z"
                  fill="#5F22C1"
                />
              </svg>
            </div>
            <div class="sort__rating">
              <div
                v-for="theme in themes"
                :key="theme.id"
                class="sort__rating-item"
                @click="handleSortClick"
                :data-sorttheme="theme.name"
              >
                {{ theme.name }}
              </div>
            </div>
          </div>
          <div
            class="products__sort-rating"
            @mouseover="enterSort"
            @mouseleave="leaveSort"
            @click="toggleSort"
          >
            <div class="sort__text">
              <span>Дата добавления</span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                height="18"
                viewBox="0 0 18 18"
                fill="none"
              >
                <path
                  d="M9.64021 11.7682C9.26936 12.0773 8.73069 12.0773 8.35984 11.7682L2.35984 6.76822C1.93556 6.41466 1.87824 5.78409 2.2318 5.35982C2.58537 4.93554 3.21593 4.87821 3.64021 5.23178L9.00003 9.69829L14.3598 5.23178C14.7841 4.87821 15.4147 4.93554 15.7682 5.35982C16.1218 5.78409 16.0645 6.41466 15.6402 6.76822L9.64021 11.7682Z"
                  fill="#5F22C1"
                />
              </svg>
            </div>
            <div class="sort__rating">
              <div
                class="sort__rating-item"
                @click="sortItem"
                data-sort="allVideos"
              >
                Все города
              </div>
              <div
                class="sort__rating-item"
                @click="sortItem"
                data-sort="newVideos"
              >
                Сначала новые
              </div>
              <div
                class="sort__rating-item"
                @click="sortItem"
                data-sort="oldVideos"
              >
                Сначала старые
              </div>
            </div>
          </div>
          <!-- <div
            v-if="currentCategories.length > 0"
            class="products__sort-rating katFilteres"
            @mouseover="enterSort"
            @mouseleave="leaveSort"
            @click="toggleSort"
          >
            <div class="sort__text">
              <span>Категории</span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                height="18"
                viewBox="0 0 18 18"
                fill="none"
              >
                <path
                  d="M9.64021 11.7682C9.26936 12.0773 8.73069 12.0773 8.35984 11.7682L2.35984 6.76822C1.93556 6.41466 1.87824 5.78409 2.2318 5.35982C2.58537 4.93554 3.21593 4.87821 3.64021 5.23178L9.00003 9.69829L14.3598 5.23178C14.7841 4.87821 15.4147 4.93554 15.7682 5.35982C16.1218 5.78409 16.0645 6.41466 15.6402 6.76822L9.64021 11.7682Z"
                  fill="#5F22C1"
                />
              </svg>
            </div>
            <div class="sort__rating">
              <div
                class="sort__rating-item sortKat"
                @click="sortItem"
                data-sort="allVideos"
              >
                Все видео
              </div>
              <div
                v-for="k of currentCategories"
                :key="k.id"
                class="sort__rating-item sortKat"
                :data-sort="k.name"
                @click="sortItem"
              >
                {{ k.name }}
              </div>
            </div>
          </div> -->
        </div>
      </div>
    </div>
  </div>
  <FooterBlock />
</template>
<style scoped></style>