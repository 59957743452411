import { createApp } from 'vue'

import router from './router/router'
import '../src/assets/css/style.css';
import App from './App.vue'
// import $ from 'jquery'
import store from "@/store";

createApp(App).use(store).use(router).mount('#app')

